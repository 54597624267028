@import '../../../assets/css/_config.scss';

.Separator {
  height: rem(1px);

  &::after {
    content: '';
    display: block;
    height: rem(1px);
    width: 100%;
    position: absolute;
    right: 0;
    left: 0;
    background: rgba(var(--c-black-rgb), 0.1);
  }

  &.is-top {
    &::after {
      top: 0;
    }
  }

  &.is-bottom {
    &::after {
      bottom: 0;
    }
  }

  &.is-inline {
    &::after {
      position: static;
    }
  }
}
