@import '../../assets/css/_config.scss';

.FlexCell {
  --flexCell-basis: ;
  --flexCell-shrink: 1;
  --flexCell-grow: 0;
  --flexCell-align: auto;
  --flexCell-sm: var(--flex-cols);
  --flexCell-md: var(--flex-cols);
  --flexCell-span: var(--flex-sm);

  flex-basis: var(--flexCell-basis, calc(100% / var(--flex-cols) * var(--flexCell-span)));
  flex-shrink: var(--flexCell-shrink);
  flex-grow: var(--flexCell-grow);
  align-self: var(--flexCell-align);
  max-width: calc(100% / var(--flex-cols) * var(--flexCell-span));

  @include media-breakpoint-up(md) {
    --flexCell-span: var(--flex-md);
  }
}
