@import '../../../../assets/css/_config.scss';

.LandingLayout {
  --landing-foreground: var(--c-black);
  --landing-background: var(--c-white);

  background: var(--landing-background);
  color: var(--landing-foreground);
  max-width: 38em;
  padding: 2em 1em;
  position: relative;
  margin: 1em;

  &.is-left,
  &.is-right {
    @include media-breakpoint-up(md) {
      padding-left: 3em;
      padding-right: 3em;
      position: fixed;
      margin: 0;

      bottom: 0;
      top: 0;
      overflow: auto;
    }
  }

  &.is-left {
    left: 0;
  }

  &.is-right {
    right: 0;
  }
}

.LandingLayout-title {
  align-items: center;
}

.LandingLayout-caption {
  display: none;
  text-align: center;

  @include media-breakpoint-up(md) {
    display: block;
    font-size: inherit;
    margin-top: 1em;
  }
}

.LandingLayout-content {
}
