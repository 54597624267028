@import '../../../../assets/css/_config.scss';

.LoginForm {
  margin: 5em 0 1em;
}

.LoginForm-form {
  margin: 1em 2rem;
  .LoginForm-instruction-text {
    color: var(--c-error);
    margin: 1em 0;
  }
}

.LoginForm-login {
  margin-top: 2em;
  border: 1px solid var(--c-secondary);
  border-radius: 4px;

  .LoginForm-choice {
    color: var(--c-secondary);
  }
}

.LoginForm-actions {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  & > * {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  @include media-breakpoint-up(md) {
    & > * {
      width: auto;
    }
  }
}

.LoginForm-errors {
  margin: 1em 0 2em;
}

.LoginForm-choice {
  text-transform: uppercase;
  font-family: var(--fontFamily-secondary);
  width: 100%;
  white-space: normal;
  display: inline-flex;
  align-items: center;
  gap: 1em;
  padding: 0.25em 2rem;
  min-height: var(--t-size-lg);

  span {
    text-transform: lowercase;
    white-space: nowrap;
  }
}

.LoginForm-tick {
  margin-left: auto;
  border: 1px solid;
  width: 1.8em;
  height: 1.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 4px;
}
