@keyframes spinnerAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Spinner {
  --spinner-color: var(--c-black);

  color: var(--spinner-color);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-top: 3px solid transparent;
  border-left: 3px solid;
  border-bottom: 3px solid;
  border-right: 3px solid;
  display: inline-block;

  &,
  & * {
    animation-name: spinnerAnimation;
    animation-duration: 1s;
    animation-play-state: running;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  &.is-primary {
    --spinner-color: var(--c-primary);
  }

  &.is-secondary {
    --spinner-color: var(--c-secondary);
  }
}
