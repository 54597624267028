@import '../../../assets/css/_config.scss';

.ServiceSlider {
  --Container-row-column: var(--Container-column-large);

  --ServiceSlider-gap: var(--t-gutter-lg);
  --ServiceSlider-padding-x: var(--ServiceSlider-gap);
  --ServiceSlider-padding-y-sm: var(--t-gutter-lg);
  --ServiceSlider-padding-y-md: var(--t-gutter-xl);

  background-color: var(--c-grey-semi);
  display: grid;
  grid-gap: var(--ServiceSlider-gap);
  grid-auto-flow: column;
  grid-auto-columns: calc(75% - var(--ServiceSlider-gap));
  overflow: auto;
  padding: var(--ServiceSlider-padding-y-sm) var(--ServiceSlider-padding-x);
  scroll-snap-type: x mandatory;
  scroll-padding: var(--ServiceSlider-padding-x);

  @include media-breakpoint-up('sm') {
    grid-auto-columns: calc(50% - var(--ServiceSlider-padding-x));
  }

  @include media-breakpoint-up('md') {
    grid-auto-columns: 180px;
    padding: var(--ServiceSlider-padding-y-md) var(--ServiceSlider-padding-x);
  }

  & > * {
    scroll-snap-align: start;
  }
}

.ServiceSlider--shrunk {
  --ServiceSlider-padding-y-sm: var(--t-gutter-md);
  --ServiceSlider-padding-y-md: var(--t-gutter-md);

  grid-auto-columns: 300px;
}