@import '../../assets/css/_config.scss';

.FormControl {
  --formControl-gutter: var(--t-gutter-sm);
  --formControl-borderColor: transparent;
  --formControl-borderWidth: 1px;
  --formControl-borderRadius: 4px;

  display: flex;
  gap: var(--formControl-gutter);
  flex-direction: column;
  border: var(--formControl-borderWidth) solid var(--formControl-borderColor);
  border-radius: var(--formControl-borderRadius);

  &.is-inline {
    display: inline-flex;
  }

  &.is-flat,
  &.is-row {
    flex-direction: row;
    align-items: center;
  }

  &.is-wrap {
    flex-wrap: wrap;
  }

  &.is-outline {
    --formControl-borderColor: rgba(var(--c-black-rgb), 0.2);

    padding: 0.75em 1em;

    @include media-breakpoint-up(sm) {
      padding: 1.5em 2em;
    }
  }

  &.is-indent {
    margin-left: 1.5em;
  }
}
