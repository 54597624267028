@import '../../../assets/css/_config.scss';

@keyframes placeloaderAnimation {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 0.1;
  }
}

.Placeloader {
  --placeloader-width: 100%;
  --placeloader-height: 1em;
  --placeloader-color: currentColor;

  display: inline-block;
  height: var(--placeloader-height);
  width: var(--placeloader-width);
  background: var(--placeloader-color);
  opacity: 0.1;
  animation: placeloaderAnimation 1s infinite;
}

.Placeloader-label {
  @include u-srOnly;
}
