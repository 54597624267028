@import '../../../assets/css/_config.scss';

@keyframes pageLoaderAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.PageLoader {
  --pageLoader-rootSize: 90px;
  --pageLoader-iconSize: 80px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 2em 0;

  &.is-full {
    min-height: calc(100vh - 280px);
  }
}

.PageLoader-icon {
  display: inline-block;
  position: relative;
  width: var(--pageLoader-rootSize);
  height: var(--pageLoader-rootSize);
  align-self: center;
  color: var(--c-primary);

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: var(--pageLoader-iconSize);
    height: var(--pageLoader-iconSize);
    margin: 6px;
    border: 6px solid;
    border-radius: 50%;
    animation: pageLoaderAnimation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

.PageLoader-label {
  @include u-srOnly;
}

.PageLoader-text {
  align-self: center;
  font-size: var(--fontSize-lg);
  margin-top: 1em;
}
