@import '../../../assets/css/_config.scss';

.Typography {
  --typography-foreground: inherit;
  --typography-fontFamily: inherit;
  --typography-fontWeight: inherit;
  --typography-fontSize: inherit;
  --typography-textAlign: inherit;

  color: var(--typography-foreground);
  font-family: var(--typography-fontFamily);
  font-weight: var(--typography-fontWeight);
  font-size: var(--typography-fontSize);
  text-align: var(--typography-textAlign);

  &.is-nowrap {
    white-space: nowrap;
  }

  &.is-primary {
    --typography-foreground: var(--c-primary);
  }

  &.is-secondary {
    --typography-foreground: var(--c-secondary);
  }

  &.is-font-primary {
    --typography-fontFamily: var(--fontFamily-primary);
  }

  &.is-font-secondary {
    --typography-fontFamily: var(--fontFamily-secondary);
  }

  &.is-normal {
    --typography-fontWeight: var(--fontWeight-normal);
  }

  &.is-bold {
    --typography-fontWeight: var(--fontWeight-bold);
  }

  &.is-xxs {
    --typography-fontSize: var(--fontSize-xxs);
  }

  &.is-xs {
    --typography-fontSize: var(--fontSize-xs);
  }

  &.is-sm {
    --typography-fontSize: var(--fontSize-sm);
  }

  &.is-md {
    --typography-fontSize: var(--fontSize-md);
  }

  &.is-lg {
    --typography-fontSize: var(--fontSize-lg);
  }

  &.is-xl {
    --typography-fontSize: var(--fontSize-xl);
  }

  &.is-xxl {
    --typography-fontSize: var(--fontSize-xxl);
  }

  &.is-xxxl {
    --typography-fontSize: var(--fontSize-xxxl);
  }

  &.is-center {
    --typography-textAlign: var(--textAlign-center);
  }

  &.is-justify {
    --typography-textAlign: var(--textAlign-justify);
  }

  &.is-start {
    --typography-textAlign: var(--textAlign-start);
  }

  &.is-end {
    --typography-textAlign: var(--textAlign-end);
  }
}
