@import '../../../assets/css/_config.scss';

.MiniTeaser {
  display: flex;
}

.MiniTeaser-media {
  margin-right: 1em;
  flex-basis: 3.5em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-primary);
}

.MiniTeaser-content {
  flex-grow: 1;
  flex-basis: 100%;
}

.MiniTeaser-subtitle {
  font-size: rem(14px);
  color: var(--c-grey);
}

.MiniTeaser-title {
  font-family: var(--fontFamily-secondary);
  font-weight: var(--fontWeight-normal);
  color: var(--c-black);
  text-decoration: none;
}

a,
button {
  &.MiniTeaser-title {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
