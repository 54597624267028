@import '../../../assets/css/_config.scss';

.ServiceList {
  --serviceList-transformDuration: 300ms;
  z-index: var(--zindex-serviceList);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(calc(100% - var(--serviceList-buttonHeight)));
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform var(--serviceList-transformDuration);

  &.is-open {
    transform: translateY(0);
  }

  @include media-breakpoint-up('md') {
    position: initial;
    transform: initial;
    transition: initial;
  }
}

.ServiceList-switcher {
  display: flex;
  justify-content: center;
  font-size: rem(14px);
  font-family: var(--fontFamily-secondary);
  font-weight: var(--fontWeight-bold);
}

.ServiceList-panel {
  background-color: var(--c-grey-semi);
  overflow: auto;
  flex-grow: 1;
}

.ServiceList-grid {
  --grid-gap: #{rem(24px)};
  --tile-width: #{rem(140px)};

  display: grid;
  grid-gap: var(--grid-gap);
  grid-template-columns: repeat(auto-fill, minmax(var(--tile-width), 1fr));
  padding: rem(24px) 0;

  &.is-notSwipeable {
    padding-top: rem(40px);
    padding-bottom: rem(40px);
    overflow-x: scroll;
    display: grid;
    grid-auto-flow: column;
    grid-gap: rem(16px);
    grid-template-columns: initial;

    .ServiceTile {
      width: rem(155px);
    }
  }

  @include media-breakpoint-up('md') {
    --tile-width: #{rem(180px)};
    padding: rem(48px) 0;
  }
}

.ServiceList-sticky {
  background-color: var(--c-primary);
  color: var(--c-white);
  flex-shrink: 0;
  padding-bottom: rem(16px);
}

.ServiceList-button {
  @include btn-reset;

  display: block;
  width: 100%;
  cursor: pointer;
  font-family: var(--fontFamily-secondary);
  font-weight: var(--fontWeight-bold);
  font-size: rem(20px);
  padding: rem(14px);
  margin-bottom: rem(12px);

  &:before {
    content: '';
    display: block;
    width: 43px;
    margin: auto;
    border-top: 4px solid;
    border-radius: 2px;
    margin-bottom: rem(12px);
  }
}

.ServiceList-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ServiceList-chevron {
  width: rem(24px);
  height: rem(24px);
  background: var(--c-white);
  color: var(--c-primary);
  border-radius: 50%;
  transition: transform var(--serviceList-transformDuration);
  margin-right: 0.5em;
}

.ServiceList-button[aria-expanded='true'] .ServiceList-chevron {
  transform: rotateZ(180deg);
}

.ServiceList-highlight {
  display: flex;
  flex-direction: column;
  height: rem(206px);
  border-radius: rem(4px);
  justify-content: space-between;
  background-color: var(--c-primary);
  color: var(--c-white);
  padding: rem(28px) rem(24px);
}

.ServiceList-highlightTitle {
  font-family: var(--fontFamily-secondary);
  font-weight: var(--fontWeight-bold);
  word-spacing: 100vw;
  font-size: rem(20px);
}
