.Flex {
  --flex-cols: 12;
  --flex-gap: 0;
  --flex-display: flex;
  --flex-wrap: wrap;
  --flex-direction: row;
  --flex-alignItems: flex-start;
  --flex-justifyContent: flex-start;

  display: var(--flex-display);
  flex-wrap: var(--flex-wrap);
  flex-direction: var(--flex-direction);
  align-items: var(--flex-alignItems);
  justify-content: var(--flex-justifyContent);
  gap: var(--flex-gap);
}

.Flex.has-gutter {
  margin: calc(var(--flex-gap) * -1 / 2);
  gap: unset;

  > * {
    margin: calc(var(--flex-gap) / 2);
  }
}
