.Toast {
  margin-left: auto;
  padding: 0.5em;
  background-color: var(--c-secondary);
  color: var(--c-white);
  font-size: 0.8rem;
  line-height: 1.5em;
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px 0 rgba(var(--c-black-rgb), 0.3);
}

.Toast-image {
  margin: 0.5em 1em 0.5em 0.5em;
}

.Toast-text {
  a {
    color: inherit;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.Toast-title {
  font-weight: bold;
  text-transform: uppercase;
}

.Toast-message {
}

.Toast-close {
  margin-left: auto;
  padding-left: 2em;
}
