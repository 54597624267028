@import '../../../assets/css/_config.scss';

.Breadcrumb {
  display: flex;
  margin-top: rem(40px);
  margin-bottom: rem(8px);

  .Icon {
    width: rem(24px);
    height: rem(24px);
    fill: var(--c-black);
  }

  @media (max-width: 749px) {
    margin-top: rem(32px);
    margin-bottom: rem(21px);
  }
}

.Breadcrumb-list {
  display: flex;
  flex-wrap: wrap;
  color: var(--c-black);
}

.BreadcrumItem {
  display: flex;
  align-items: center;
  font-family: var(--fontFamily-primary);
  font-weight: var(--fontWeight-normal);
  font-size: rem(13px);
}
