@import '../../../assets/css/_config.scss';

.SearchForm {
  --SearchForm-height: 40px;
  --SearchForm-borderRadius: calc(var(--SearchForm-height) / 2);
  --SearchForm-borderColor: var(--c-secondary);

  display: flex;
  background-color: var(--c-grey-semi);
  border-radius: var(--SearchForm-borderRadius);
  border: 1px solid transparent;
  transition: border-color $transition-duration, color $transition-duration, background-color $transition-duration;

  &:focus-within {
    border-color: var(--SearchForm-borderColor);
  }

  .has-transparency & {
    backdrop-filter: blur(31px);
    background-color: rgba(var(--c-white-rgb), 0.25);
  }
}

.SearchForm-label {
  @include u-srOnly;
}

.SearchForm-input {
  font-family: var(--fontFamily-primary);
  font-weight: var(--fontWeight-normal);
  font-size: rem(15px);
  color: inherit;
  width: rem(250px);
  flex-grow: 1;
  height: var(--SearchForm-height);
  padding: rem(8px) rem(16px) rem(8px) rem(24px);
  background-color: transparent;
  border: 0;
  outline: 0;

  @include input-placeholder {
    color: inherit;
  }
}

.SearchForm-submit,
.SearchForm-cancel {
  @include btn-reset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--SearchForm-height);
  height: var(--SearchForm-height);
  border-radius: 50%;
  transition: color $transition-duration, background-color $transition-duration;

  .Icon {
    width: rem(24px);
    height: rem(24px);
    fill: currentColor;
  }
}

.SearchForm-cancel {
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--c-grey-semi);
  }
}
