@import '../../../../assets/css/_config.scss';

.TwoColumnsLayout {
}

.TwoColumnsLayout-content {
  background-color: var(--c-white);
  max-width: rem(1280px);
  width: 100%;
  margin: 0 auto;
  margin-bottom: rem(32px);

  @include media-breakpoint-up('md') {
    display: grid;
    grid-template-areas: 'main aside';
    grid-template-columns: 2fr 1fr;
  }
}

.TwoColumnsLayout-main {
  grid-area: main;
  padding-top: 2em;

  @include media-breakpoint-up('md') {
    padding-right: var(--t-gutter-xxl);
  }
}

.TwoColumnsLayout-aside {
  grid-area: aside;
}
