.Toaster {
  margin: auto;
  max-width: calc(100% - 2.5rem);
  width: var(--t-width-xl);
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 0.5rem;
  }

  &.is-sticky {
    position: fixed;
    right: 1em;
    top: 1em;
    z-index: var(--zindex-toaster);
    width: auto;
  }
}
