@import '../../../assets/css/config';

:root {
  --checkboxInput-height: #{rem(26px)};
  --checkboxInput-switch-width: #{rem(48px)};
  --checkboxInput-switch-cocheSize: #{rem(18px)};
  --checkboxInput-background: var(--c-black-rgb);
  --checkboxInput-foreground: var(--c-white-rgb);
  --checkboxInput-gap: 1em;
  --checkboxInput-borderColor: var(--c-grey);
}

.CheckboxInput {
  display: inline-flex;
  align-items: center;

  input {
    border: 0 !important;
    clip: rect(0em, 0em, 0em, 0em);
    font-size: 0;
    height: 0 !important;
    margin: -1px;
    overflow: hidden;
    padding: 0 !important;
    width: 0 !important;
    position: fixed;
    left: -1rem;
    top: -1rem;
  }

  label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  input + label {
    position: relative;
    padding-left: calc(var(--checkboxInput-height) + var(--checkboxInput-gap));
  }

  &.is-srOnly {
    label {
      --checkboxInput-gap: 0px;
    }

    .CheckboxInput-labelContent {
      @include u-srOnly;
    }
  }

  input + label::before,
  input + label:after {
    position: absolute;
    display: block;
    height: var(--checkboxInput-height);
    width: var(--checkboxInput-height);
  }

  input + label::before {
    left: 0;
    content: '';
    background-color: var(--c-white);
    border: 1px solid var(--checkboxInput-borderColor);
    border-radius: rem(4px);
    transition: background-color $transition-duration, border-color $transition-duration;
  }

  input:focus,
  input:hover {
    + label:before {
      box-shadow: 0 0 0 2px rgba(var(--checkboxInput-background), 0.3);
    }
  }

  input + label::after {
    top: calc(50% - 5px);
    left: calc(var(--checkboxInput-height) / 2 - 5px);
    content: '';
    width: rem(11px);
    height: rem(7px);
    border-left: 1px solid rgb(var(--checkboxInput-foreground));
    border-bottom: 1px solid rgb(var(--checkboxInput-foreground));
    transform: rotateZ(-45deg);
    transition: opacity $transition-duration;
  }

  input:checked + label::before {
    background-color: rgb(var(--checkboxInput-background));
    border-color: rgb(var(--checkboxInput-background));
  }

  input:checked + label::after {
    opacity: 1;
  }

  input:disabled + label::before {
    cursor: not-allowed;
    opacity: 0.5;
  }

  input:disabled + label::after {
    cursor: not-allowed;
  }

  &.is-primary {
    --checkboxInput-background: var(--c-primary-rgb);
  }

  &.is-secondary {
    --checkboxInput-background: var(--c-secondary-rgb);
  }

  &.is-white {
    --checkboxInput-background: var(--c-white-rgb);
  }

  &.is-sm {
    --checkboxInput-height: var(--fontSize-md);
  }
}
