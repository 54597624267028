@import '../../../assets/css/_config.scss';

.TopButton {
  display: none;
  transition: 300ms ease opacity;
  opacity: 1;
  position: sticky;
  top: 1em;

  &[aria-hidden='true'] {
    opacity: 0;
  }

  .Cta {
    padding: rem(4px) rem(9px);
  }

  @include media-breakpoint-up('md') {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.TopButton-content {
  margin-right: 1em;
  font-size: var(--fontSize-sm);
}
