@import '../../../assets/css/_config.scss';

.ServiceTile {
  display: flex;
  flex-direction: column;
  min-height: rem(206px);
  border-radius: rem(4px);
  background-color: var(--c-white);

  @include media-breakpoint-up('md') {
    min-height: rem(206px);
  }
}

.ServiceTile-content {
  flex: 1;
  padding: rem(16px) rem(16px) rem(8px);
  border-radius: rem(4px);
  background-color: var(--c-white);

  .ServiceTile-icon {
    height: rem(36px);
    width: rem(36px);
    color: var(--c-primary);
  }

  @include media-breakpoint-up('md') {
    padding: rem(24px) rem(24px) rem(8px);
  }
}

a.ServiceTile-content {
  display: flex;
  flex-direction: column;
  word-break: break-word;
  text-decoration: none;
  color: inherit;
  transition: 300ms transform;

  &:hover,
  &:focus {
    transform: translateY(-0.5em);
  }
}

.ServiceTile-title,
.ServiceTile-subtitle {
  font-size: rem(15px);
}

.ServiceTile-title {
  font-family: var(--fontFamily-secondary);
  font-weight: var(--fontWeight-bold);
  margin-top: rem(24px);
  color: var(--c-primary);
}

.ServiceTile-footer {
  display: flex;
  align-items: center;
  gap: rem(8px);
  padding: rem(6px) rem(19px);
  border-top: rem(1px) solid rgba(var(--c-black-rgb), 0.1);
}

.ServiceTile-action {
  @include btn-reset;

  display: inline-flex;
  padding: rem(4px);
  cursor: pointer;
  color: rgba(var(--c-black-rgb), 0.2);
  transition: color 100ms;

  svg {
    height: rem(21px);
    width: rem(21px);
  }

  &:hover,
  &:focus {
    color: rgba(var(--c-black-rgb), 0.8);
  }

  @include media-breakpoint-up('md') {
    svg {
      height: rem(16px);
      width: rem(16px);
    }
  }
}

.ServiceTile-image {
  background: var(--c-primary);
  padding: 1em;
  border-radius: 4px;
  max-height: 7em;
  text-align: center;

  img {
    max-height: 100%;
    margin: auto;
  }
}

.ServiceTile.is-favorite {
  .ServiceTile-favoriteToggle {
    color: rgba(var(--c-primary-rgb), 0.8);
  }
}

.ServiceTile--shrunk {
  min-height: auto;
  max-width: 100%;

  .ServiceTile-content {
    flex-direction: row;
    padding: var(--t-gutter-sm) var(--t-gutter-md);
    gap: var(--t-gutter-md);
  }

  .ServiceTile-title {
    margin: 0;
  }

  .ServiceTile-footer {
    display: none;
  }
}