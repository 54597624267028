@import '../../../assets/css/config';

.Banner {
  position: relative;
  display: flex;
  align-items: flex-end;
  min-height: rem(280px);
  margin-top: calc(var(--header-height) * -1);
  overflow: hidden;

  @include media-breakpoint-up('md') {
    &.is-big {
      min-height: rem(440px);
    }

    &.has-offset {
      margin-bottom: rem(-100px);
      z-index: 0;
    }
  }
}

.Banner-img {
  position: absolute;
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  * {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 0%;
  }
}

.Banner-content {
  position: relative;
  margin-top: calc(var(--header-height) + #{rem(60px)});
}
