@import '../../../assets/css/_config.scss';

.Paragraph-file {
  margin-top: 1em;
  list-style: none !important;

  & + .Paragraph-file {
    margin-top: 0.5em;
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    border: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &:before,
    &:hover::before {
      width: rem(42px);
      height: rem(32px);
      display: inline-block;
      margin-right: rem(8px);
      border-radius: rem(20px);
    }

    &:before {
      content: '';
      background: var(--c-secondary);
      background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTE5IDE0djRINXYtNG03LjI5My0uMDVWMy42MzZtMy41MzUgNi43NzgtMy41MzUgMy41MzYtMy41MzYtMy41MzYiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlLXdpZHRoPSIxLjUiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgo8L3N2Zz4K');
      background-repeat: no-repeat;
      background-size: rem(24px);
      background-position: center;
    }

    &:hover::before {
      border: rem(1px) solid rgba(var(--c-black-rgb), 0.2);
      background: var(--c-white);
      background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0iTTE5IDE0djRINXYtNG03LjI5My0uMDVWMy42MzZtMy41MzUgNi43NzgtMy41MzUgMy41MzYtMy41MzYtMy41MzYiIHN0cm9rZT0iIzFBMUExQSIgc3Ryb2tlLXdpZHRoPSIxLjUiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgo8L3N2Zz4K');
      background-repeat: no-repeat;
      background-size: rem(24px);
      background-position: center;
    }
  }
}
