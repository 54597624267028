// Config : Colors variables
// =========================
//

:root {
  --c-primary: #e2001a;
  --c-primary-rgb: 226, 0, 26;
  --c-secondary: #5569da;
  --c-secondary-rgb: 85, 105, 218;
  --c-tertiary: #9500a0;

  --c-grey-light: #f4f2f1;
  --c-grey-light-rgb: 244, 242, 241;
  --c-grey-semi: #f0ecea;
  --c-grey-semi-rgb: 240, 236, 234;
  --c-grey: #b5b5b5;
  --c-grey-rgb: 181, 181, 181;
  --c-grey-dark: #727170;
  --c-grey-dark-rgb: 114, 113, 112;
  --c-black: #1a1a1a;
  --c-black-rgb: 26, 26, 26;
  --c-white: #fff;
  --c-white-rgb: 255, 255, 255;
  --c-error: #e2001a;
  --c-error-rgb: 226, 0, 26;

  --t-size-xs: #{rem(24px)};
  --t-size-sm: #{rem(32px)};
  --t-size-md: #{rem(40px)};
  --t-size-lg: #{rem(56px)};
  --t-size-xl: #{rem(64px)};

  --t-width-xxs: 15rem;
  --t-width-xs: 20rem;
  --t-width-sm: 33.25rem;
  --t-width-md: 48rem;
  --t-width-lg: 72rem;
  --t-width-xl: 80rem;
  --t-width-xxl: 100rem;

  --t-gutter-none: 0rem;
  --t-gutter-xxs: 0.25rem;
  --t-gutter-xs: 0.5rem;
  --t-gutter-sm: 0.75rem;
  --t-gutter-md: 1rem;
  --t-gutter-lg: 1.5rem;
  --t-gutter-xl: 2.25rem;
  --t-gutter-xxl: 3.5rem;
  --t-gutter-xxxl: 8rem;

  --header-height: #{rem(72px)};
  --serviceList-buttonHeight: #{rem(72px)};

  --fontFamily-primary: 'Noto Serif', Arial, 'Trebuchet MS', serif;
  --fontFamily-secondary: 'Poppins', sans-serif;
  --fontWeight-normal: 400;
  --fontWeight-bold: 600;

  --fontSize-xxs: #{rem(10px)};
  --fontSize-xs: #{rem(13px)};
  --fontSize-sm: #{rem(15px)};
  --fontSize-md: #{rem(16px)};
  --fontSize-lg: #{rem(20px)};
  --fontSize-xl: #{rem(24px)};
  --fontSize-xxl: #{rem(32px)};
  --fontSize-xxxl: #{rem(48px)};

  --textAlign-center: center;
  --textAlign-justify: justify;
  --textAlign-start: left;
  --textAlign-end: right;

  --grid-gap: #{rem(24px)};

  --zindex-toaster: 280;
  --zindex-tooltip: 270;
  --zindex-tooltip: 260;
  --zindex-dialog: 250;
  --zindex-searchPanel: 210;
  --zindex-serviceList: 200;
  --zindex-header: 190;
  --zindex-backdrop: 180;
  --zindex-overall: 10;
  --zindex-sidepanel: 6;
  --zindex-nav: 3;
  --zindex-content: 1;
}
