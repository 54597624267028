@import '../../../assets/css/_config.scss';

.Footer {
  --footer-foreground: var(--c-black);
  --footer-background: var(--c-white);

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: rem(202px);
  color: var(--footer-foreground);
  background-color: var(--footer-background);

  @include media-breakpoint-down(md) {
    min-height: rem(333px);
    margin-bottom: var(--serviceList-buttonHeight);
  }
}

.Footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2em;
  align-items: flex-end;
  padding: 0 rem(64px) rem(24px) rem(32px);

  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;
    padding: rem(40px) rem(16px) rem(16px) rem(24px);
  }
}

.Footer-linksList,
.Footer-socialsList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Footer-linksList,
.Footer-socials {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

.Footer-linksList {
  .Footer-linkItem {
    @include media-breakpoint-down(md) {
      &:not(:last-child) {
        margin-bottom: rem(14px);
      }
    }
  }

  a {
    font-family: var(--fontFamily-primary);
    font-weight: var(--fontWeight-normal);
    font-size: rem(13px);
    color: var(--footer-foreground);
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.Footer-socialsList {
  display: flex;
  padding: 0;

  @include media-breakpoint-down(md) {
    margin-top: rem(14px);
  }

  a {
    height: rem(24px);
    color: var(--c-primary);
    transition: color $transition;

    svg {
      height: 24px;
      width: 24px;
      fill: currentColor;
    }

    &:hover,
    &:focus {
      color: var(--footer-foreground);
    }
  }
}

.Footer-findUs {
  font-family: var(--fontFamily-secondary);
  font-weight: var(--fontWeight-bold);
  font-size: rem(15px);
  color: var(--c-primary);
  margin-right: rem(26px);

  @include media-breakpoint-down(md) {
    margin-right: 0;
  }
}

.Footer-linkItem {
  display: flex;
}

.Footer-divider,
.Footer-dividerIcon {
  width: rem(1px);
  height: rem(16px);
  opacity: 0.1;
  background-color: var(--footer-foreground);
}

.Footer-divider {
  margin: 0 rem(16px) 0;

  @include media-breakpoint-down(md) {
    margin: 0 rem(16px) 0 0;
  }
}

.Footer-dividerIcon {
  margin: rem(4px) rem(14px);

  @include media-breakpoint-down(md) {
    margin: rem(4px) rem(10px);
  }
}

.Footer-firstDivider {
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.Footer-firstIconDivider {
  margin: rem(4px) rem(14px) rem(4px) 0;

  @include media-breakpoint-down(md) {
    margin: rem(4px) rem(10px) rem(4px) 0;
  }
}

.Footer-logo {
  display: flex;
  align-items: flex-end;
  margin-left: auto;
}

.Footer-icon {
  height: rem(121px);
  width: rem(121px);
}
