@import '../../../assets/css/_config.scss';

@keyframes dialogOpen {
  0% {
    opacity: 0;
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes dialogclose {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 1);
  }
}

.Dialog {
  --dialog-width: auto;
  --dialog-foreground: var(--c-black);
  --dialog-background: var(--c-white);
  --dialog-backdrop: rgba(10, 10, 10, 0.7);

  background: var(--dialog-backdrop);
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;

  transition: opacity 250ms ease-in-out;
  opacity: 0;

  &[data-enter] {
    opacity: 1;
  }
}

.Dialog-popup {
  position: relative;
  min-height: 0;
  max-height: calc(100% - 2rem);
  width: var(--dialog-width);
  max-width: calc(100% - 2rem);
  overflow: auto;
  z-index: var(--zindex-dialog);

  animation-duration: 0.3s;
  animation-fill-mode: forwards;

  &[data-enter] {
    animation-name: dialogOpen;
  }

  &[data-leave] {
    animation-name: dialogClose;
  }
}

.Dialog-component {
  height: 100%;
  width: 100%;
  background: var(--dialog-background);
  color: var(--dialog-foreground);
  padding: 32px 48px 48px 56px;
}

.Dialog-title {
  margin-top: rem(17px);
  margin-bottom: rem(24px);
  padding-inline-end: rem(64px);

  &::after {
    content: '';
    display: block;
    border-bottom: rem(1px) solid;
    position: absolute;
    left: 0;
    width: 100%;
    opacity: 0.1;
    margin-top: rem(24px);
  }
}

.Dialog-content {
  padding-top: rem(24px);
  overflow: auto;
}

.Dialog-importantMessage {
  padding-top: rem(30px);
  padding-bottom: rem(10px);
  display: flex;
  flex-direction: row-reverse;
  color: var(--c-primary);
  font-size: rem(15px);
}

.Dialog-actionList {
  padding-top: rem(40px);
  display: flex;
  flex-wrap: wrap;
  gap: rem(12px);
  &.importantMessage {
    padding-top: 0;
  }
}

.Dialog-closeButton {
  display: block;
  position: absolute;
  right: 48px;
  top: 32px;

  .Cta {
    padding: rem(7px) rem(12px);
  }
}

@include media-breakpoint-down(sm) {
  .Dialog-component {
    padding: 16px 24px 24px 24px;
  }

  .Dialog-closeButton {
    right: 24px;
    top: 16px;
  }
}
