@import '../../../assets/css/_config.scss';

.DayDate {
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  text-align: center;
  font-family: var(--fontFamily-secondary);
}

.DayDate-month {
  font-weight: var(--fontWeight-bold);
  text-transform: uppercase;
  font-size: rem(12px);
  color: var(--c-primary);
}

.DayDate-day {
  font-weight: var(--fontWeight-normal);
  font-size: rem(28px);
  color: var(--c-black);
  line-height: 1;
}
