@import '../../../assets/css/_config.scss';

.Tooltip {
  --tooltip-foreground: var(--c-white);
  --tooltip-background: var(--c-black);

  z-index: var(--zindex-tooltip);
  color: var(--tooltip-foreground);
  background: var(--tooltip-background);
  max-width: rem(284px);
  padding: rem(8px) rem(16px);

  transition: opacity 250ms ease-in-out;
  opacity: 0;

  &[data-enter] {
    opacity: 1;
  }

  &.is-large {
    padding: rem(36px) rem(40px) rem(48px);
  }
}

.Tooltip-arrow {
  margin-top: rem(-2px);
}

.Tooltip-title {
  font-size: rem(15px);
  font-family: var(--fontFamily-primary);
  font-weight: var(--fontWeight-bold);
  margin-bottom: rem(6px);
}

.Tooltip-content {
  font-size: rem(13px);
}

.Tooltip-close {
  @include btn-reset;

  position: absolute;
  right: rem(16px);
  top: rem(16px);

  @include media-breakpoint-up('md') {
    display: none;
  }
}

.Tooltip-closeIcon {
  width: rem(24px);
  height: rem(24px);
}
