@import '../../../assets/css/_config.scss';

.UserMenu {
  display: flex;
  align-items: stretch;
  padding: 0;

  @include media-breakpoint-up('md') {
    padding: 0 rem(24px);
  }
}

.UserMenu-button {
  font-family: var(--fontFamily-secondary);
  font-weight: var(--fontWeight-bold);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: rem(15px);
  color: var(--c-white);
  text-decoration: none;
  background: none;
  border: 0;
  transition: 200ms box-shadow;
  cursor: pointer;

  svg {
    height: rem(24px);
    width: rem(24px);
    fill: currentColor;
  }

  &:hover,
  &:focus,
  &[aria-expanded='true'] {
    box-shadow: 0px -4px var(--c-white) inset;
    outline: 0;
  }

  @include media-breakpoint-down('md') {
    min-width: rem(48px);
  }
}

.UserMenu-name {
  @include media-breakpoint-down('lg') {
    @include u-srOnly;
  }
}

.UserMenu-content {
  background-color: var(--c-white);
  color: var(--c-black);
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px 0 rgba(var(--c-black-rgb), 0.15);
  outline: 0;

  a,
  button {
    cursor: pointer;
    display: inline-block;
    background: none;
    border: 0;
    border-radius: 0;
    color: inherit;
    font-family: inherit;
    font-size: var(--fontSize-md);
    text-decoration: none;
    padding: 0.75em 1em 0.75em 2em;
    text-align: right;

    &:hover,
    &:focus {
      background: rgba(var(--c-black-rgb), 0.1);
    }

    @include media-breakpoint-up('md') {
      font-size: var(--fontSize-sm);
      padding: 0.5em 1em;
    }
  }

  transition: opacity 200ms;
  opacity: 0;
  &[data-enter] {
    opacity: 1;
  }
}

.UserMenu.is-special {
  .UserMenu-link {
    color: var(--c-secondary);
  }
}
