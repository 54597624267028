@import '../../../assets/css/config';

:root {
  --radioInput-height: #{rem(24px)};
  --radioInput-switch-width: #{rem(48px)};
  --radioInput-switch-cocheSize: #{rem(18px)};
  --radioInput-background: var(--c-black-rgb);
  --radioInput-foreground: var(--c-white-rgb);
  --radioInput-gap: 0.5em;
}

.RadioInput {
  display: inline-flex;
  align-items: center;

  input {
    border: 0 !important;
    clip: rect(0em, 0em, 0em, 0em);
    font-size: 0;
    height: 0 !important;
    margin: -1px;
    overflow: hidden;
    padding: 0 !important;
    width: 0 !important;
    position: fixed;
    left: -1rem;
    top: -1rem;
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  input + label {
    position: relative;
    padding-left: calc(var(--radioInput-height) + var(--radioInput-gap));
  }

  input + label::before,
  input + label:after {
    position: absolute;
    display: block;
    height: var(--radioInput-height);
    width: var(--radioInput-height);
    border-radius: 50%;
  }

  input + label::before {
    left: 0;
    content: '';
    border: 1px solid var(--c-grey);
  }

  input:focus,
  input:hover {
    + label:before {
      box-shadow: 0 0 0 2px rgba(var(--radioInput-background), 0.3);
    }
  }

  input + label::after {
    left: 4px;
    content: '';
    width: calc(var(--radioInput-height) - 8px);
    height: calc(var(--radioInput-height) - 8px);
    background-color: rgb(var(--radioInput-background));
    transition: opacity $transition-duration;
    opacity: 0;
  }

  input:checked + label::before {
    border-color: rgb(var(--radioInput-background));
  }

  input:checked + label::after {
    opacity: 1;
  }

  input:disabled + label::before {
    cursor: not-allowed;
    opacity: 0.5;
  }

  input:disabled + label::after {
    cursor: not-allowed;
  }

  &.is-primary {
    --radioInput-background: var(--c-primary-rgb);
  }

  &.is-secondary {
    --radioInput-background: var(--c-secondary-rgb);
  }

  &.is-white {
    --radioInput-background: var(--c-white-rgb);
  }
}
