@import '../../../assets/css/_config.scss';

@mixin anchor {
  --anchor-color-rgb: var(--c-black-rgb);
  --anchor-color: rgba(var(--anchor-color-rgb));
  --anchor-borderColor: rgba(var(--anchor-color-rgb), 0.2);

  @include btn-reset;

  cursor: pointer;
  text-decoration: none;
  color: var(--anchor-color);
  border-bottom: 1px solid var(--anchor-borderColor);

  &:hover,
  &:focus {
    --anchor-borderColor: var(--anchor-color);
  }

  &.is-primary {
    --anchor-color-rgb: var(--c-primary-rgb);
  }

  &.is-secondary {
    --anchor-color-rgb: var(--c-secondary-rgb);
  }

  &.is-primary,
  &.is-secondary {
    border: 0;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
