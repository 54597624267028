@import '../../../assets/css/_config.scss';

.MainMenu {
  @include media-breakpoint-down('lg') {
    display: none;
    position: absolute;
    top: 100%;
    left: var(--header-height, 0);
    right: var(--header-height, 0);
    min-height: calc(100vh - var(--header-height, 0));
    max-width: 768px;
    margin-left: auto;
    background-color: var(--c-white);

    &.is-open {
      display: block;
    }
  }

  @include media-breakpoint-down('md') {
    left: 0;
    right: 0;
  }

  @include media-breakpoint-up('lg') {
    display: flex;
    align-items: stretch;
    height: 100%;
  }
}

.MainMenu-openBtn {
  @include btn-reset;
  display: flex;
  align-items: center;
  color: var(--c-white);
  padding: rem(24px) 0 rem(24px) rem(16px);

  svg {
    width: rem(24px);
    height: rem(24px);
    fill: currentColor;
  }

  &::after {
    content: '';
    align-self: center;
    height: 1.5rem;
    width: 1px;
    background-color: rgba(var(--c-white-rgb), 0.4);
    margin-left: rem(14px);
  }

  @include media-breakpoint-up('lg') {
    display: none;
  }
}

.MainMenu-list {
  padding: 0;
  margin: 0;
  list-style: none;

  @include media-breakpoint-up('lg') {
    display: flex;
  }
}

.MainMenu-item {
  &::after {
    content: '';
  }

  &.is-home {
    padding: 0;
  }

  @include media-breakpoint-down('lg') {
    &::after {
      position: absolute;
      left: rem(32px);
      right: rem(32px);
      height: 1px;
      background-color: rgba(var(--c-black-rgb), 0.1);
    }

    &.is-home {
      display: none;
    }
  }

  @include media-breakpoint-up('lg') {
    display: flex;
    align-items: stretch;
    padding: 0 rem(20px);

    &::after {
      align-self: center;
      height: rem(24px);
      width: 1px;
      background-color: rgba(var(--c-white-rgb), 0.4);
      transform: translate(rem(20px));
    }

    &.is-home::after {
      transform: none;
    }
  }
}

.MenuEntryFirst-content {
  max-width: 100%;
  color: var(--c-black);
  padding-bottom: rem(56px);
  background-color: var(--c-white);

  &[aria-hidden='true'] {
    display: none;
  }

  &[aria-hidden='false'] {
    display: block;
  }

  @include media-breakpoint-up('lg') {
    position: absolute;
    top: 100%;
    left: var(--header-height);
    right: var(--header-height);
    padding: rem(55px) rem(100px);
  }
}

.MenuEntryFirst-button {
  @include btn-reset;
  font-family: var(--fontFamily-secondary);
  font-weight: var(--fontWeight-bold);
  display: flex;
  justify-content: space-between;
  column-gap: rem(10px);
  align-items: center;
  width: 100%;
  font-size: rem(15px);
  color: var(--c-black);
  padding: rem(22px) rem(32px);
  background-color: var(--c-white);
  cursor: pointer;
  transition: 200ms box-shadow;

  &:hover,
  &:focus,
  &[aria-expanded='true'] {
    box-shadow: 0px -4px var(--c-white) inset;
  }

  &[aria-expanded='true'] {
    svg {
      transform: rotate(0.5turn);
    }
  }

  svg {
    height: rem(24px);
    width: rem(24px);
    fill: var(--c-primary);
    transition: transform $transition-duration;
  }

  .is-home & {
    padding: 0 rem(20px);
  }

  @include media-breakpoint-up('lg') {
    height: 100%;
    color: var(--c-white);
    padding: 0;
    background-color: var(--c-primary);

    svg {
      fill: var(--c-white);
    }
  }
}

.MenuEntryFirst-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  column-gap: rem(24px);
  row-gap: rem(32px);
  align-items: start;
  padding: 0 rem(32px);
  list-style: none;
  
  @include media-breakpoint-up('lg') {
    row-gap: rem(64px);
    padding: 0;
  }
}

.MenuEntryFirst-item {
  display: grid;
  row-gap: rem(16px);
}

.MenuEntrySecond {
  display: grid;
  row-gap: rem(10px);
  padding: 0;
  padding-left: rem(32px);
  list-style: none;
}

.MenuEntryThird-link {
  text-decoration: none;
  color: var(--c-black);

  &:hover {
    text-decoration: underline;
  }
}
