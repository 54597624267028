@import '../../../assets/css/_config.scss';

.Backdrop {
  z-index: var(--zindex-backdrop);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(var(--c-black-rgb), 0.5);
  transition: opacity 300ms;

  &[aria-hidden='true'] {
    opacity: 0;
    pointer-events: none;
  }

  &[aria-hidden='false'] {
    opacity: 1;
  }

  @media print {
    display: none !important;
  }
}
