@import '../../../assets/css/_config.scss';

.Title {
  --title-color: var(--c-black);

  &.is-primary {
    --title-color: var(--c-primary);
  }
  &.is-secondary {
    --title-color: var(--c-secondary);
  }
  &.is-white {
    --title-color: var(--c-white);
  }

  @include media-breakpoint-up('sm') {
    display: flex;
    column-gap: var(--t-gutter-md);
    align-items: center;
  }
}

.Title-icon {
  fill: currentColor;
  margin-right: var(--t-gutter-md);
}

.Title-content {
  display: flex;
  flex-direction: column;
  color: var(--title-color);
  flex-grow: 1;
}

.Title-hX {
  & time:first-letter {
    text-transform: uppercase;
  }

  @include media-breakpoint-up('md') {
    display: flex;
    align-items: center;
  }
}

.Title-actions {
  margin: 0.5em;
}

$titles: (
  'h1': (
    'xs': 36px,
    'md': 43px,
  ),
  'h2': (
    'xs': 30px,
    'md': 36px,
  ),
  'h3': (
    'xs': 22px,
    'md': 26px,
  ),
  'h4': (
    'xs': 20px,
    'md': 24px,
  ),
  'h5': (
    'xs': 18px,
    'md': 20px,
  ),
) !default;

@each $spacer, $sizes in $titles {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @if map-has-key($sizes, $breakpoint) {
      @include media-breakpoint-up($breakpoint) {
        #{$spacer},
        .Title-#{$spacer} .Title-hX {
          font-size: rem(map-get($sizes, $breakpoint));
          margin: 0;
          font-family: var(--fontFamily-secondary);
          font-weight: var(--fontWeight-bold);
        }
      }
    }
  }
}
