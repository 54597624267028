@import '../../../assets/css/_config.scss';

.Label {
  --label-foreground-rgb: var(--c-black-rgb);
  --label-background-rgb: var(--c-white-rgb);

  --label-textColor: rgb(var(--label-foreground-rgb));
  --label-borderColor: rgba(var(--label-foreground-rgb), 0.2);
  --label-backgroundColor: rgba(var(--label-background-rgb), 0);
  --label-borderRadius: #{rem(15px)};
  --label-fontSize: var(--fontSize-xs);

  @include btn-reset;

  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: var(--label-fontSize);
  color: var(--label-textColor);
  background-color: var(--label-backgroundColor);
  border: rem(1px) solid var(--label-borderColor);
  border-radius: var(--label-borderRadius);
  padding: rem(2px) rem(16px);
  transition: 200ms color, 200ms background, 200ms border;
  white-space: nowrap;

  &.is-wrap {
    white-space: normal;
  }

  &.is-primary {
    --label-foreground-rgb: var(--c-primary-rgb);
  }

  &.is-secondary {
    --label-foreground-rgb: var(--c-secondary-rgb);
  }

  &.is-fill {
    --label-textColor: rgb(var(--label-background-rgb));
    --label-borderColor: rgb(var(--label-foreground-rgb));
    --label-backgroundColor: rgb(var(--label-foreground-rgb));
    --label-borderRadius: 4px;
    --label-fontSize: var(--fontSize-sm);
  }
}

a,
button {
  &.Label {
    cursor: pointer;
    text-decoration: none;

    &:hover,
    &:focus {
      --label-backgroundColor: var(--label-borderColor);
    }
  }
}
