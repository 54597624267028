@import '../../../assets/css/_config.scss';

.Badge {
  --foreground-color: var(--c-white);
  --background-color: var(--c-black);
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  vertical-align: middle;

  &.is-primary {
    --background-color: var(--c-primary);
  }

  &.is-secondary {
    --background-color: var(--c-secondary);
  }

  &.is-white {
    --foreground-color: var(--c-black);
    --background-color: var(--c-white);
  }
}

.Badge-label {
  @include u-srOnly;
}

.Badge-value {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: calc(1.5em + 6px);
  height: calc(1.5em + 6px);
  font-size: rem(14px);
  color: var(--foreground-color);
  background-color: var(--background-color);
  border-radius: 50%;

  &.has-children {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: scale(1) translate(50%, 50%);
    transform-origin: 100% 0%;
  }
}
