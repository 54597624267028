.NotificationCard {
  &.is-read {
    opacity: 0.5;
  }

  &.is-important {
    background: var(--c-primary);

    --accordionPanel-borderColor: var(--c-primary);
  }
}

.NotificationCard-resume {
  margin-top: var(--t-space-md);
}

.NotificationCard-header {
  display: flex;
  align-items: center;
}

.NotificationCard-title {
  font-size: var(--fontSize-lg);
  font-weight: bold;
}

.NotificationCard-badge {
  width: var(--t-space-xxl);
  text-align: center;
}

.NotificationCard-content {
  margin-left: var(--t-space-xxl);
  font-weight: normal;
}

.NotificationCard-date {
  opacity: 0.5;
  font-size: var(--fontSize-xs);
}

.NotificationCard-text {
}

.NotificationCard-children {
  margin-left: var(--t-space-xxl);
  font-size: var(--fontSize-md);
}
