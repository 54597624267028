// Ajouter vos styles d'impression ici
// -----------------------------------
@media print {
  .joyride,
  #us_report_button,
  .fb-customerchat,
  .sf-toolbar {
    display: none !important;
  }

  body {
    background: white;
    color: black;
  }
}

