@import '../../../assets/css/config';


.ContainerRow {
  --Container-row-column: var(--Container-column-default);
}

.ContainerRow-large {
  --Container-row-column: var(--Container-column-large);
}

.ContainerRow-full {
  --Container-row-column: var(--Container-column-full);
}

.ContainerRow-overlay {
  background: var(--c-grey-semi);
}

.ContainerRow-separator {
  border-top: 1px solid rgba(var(--c-black-rgb), 0.1);
}