// Config : Webfonts
// =================
// Fonts import, placeholders

@font-face {
  font-family: 'Noto Serif';
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: local('NotoSerif Regular'), local('NotoSerif-Regular'),
    url('../../font/notoserif/NotoSerif-Regular.woff2') format('woff2'),
    url('../../font/notoserif/NotoSerif-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Noto Serif';
  font-display: swap;
  font-weight: 600;
  font-style: normal;
  src: local('NotoSerif Bold'), local('NotoSerif-Bold'),
    url('../../font/notoserif/NotoSerif-Bold.woff2') format('woff2'),
    url('../../font/notoserif/NotoSerif-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: local('Poppins Regular'), local('Poppins-Regular'),
    url('../../font/poppins/Poppins-Regular.woff2') format('woff2'),
    url('../../font/poppins/Poppins-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-weight: 600;
  font-style: normal;
  src: local('Poppins Semibold'), local('Poppins-Semibold'),
    url('../../font/poppins/Poppins-Semibold.woff2') format('woff2'),
    url('../../font/poppins/Poppins-Semibold.woff') format('woff');
}

// @font-face {
//   font-family: 'Poppins';
//   font-display: swap;
//   font-weight: 700;
//   font-style: normal;
//   src: local('Poppins Bold'), local('Poppins-Bold'),
//     url('../../font/poppins/Poppins-Bold.woff2') format('woff2'),
//     url('../../font/poppins/Poppins-Bold.woff') format('woff');
// }
