@import '../../../assets/css/_config.scss';

.SearchPanel {
  --SearchPanel-height: 40px;

  color: var(--Header-textColor);
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.SearchPanel-openButton {
  @include btn-reset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--SearchPanel-height);
  height: var(--SearchPanel-height);
  border-radius: 50%;
  transition: color $transition-duration, background-color $transition-duration;
  backdrop-filter: blur(31px);
  background-color: rgba(var(--c-white-rgb), 0.25);

  &:hover,
  &:focus {
    background-color: var(--c-grey-semi);
  }

  .Icon {
    pointer-events: none; // Necessary to fix weird bug there SVG does not work `.contains`, @see https://stackoverflow.com/questions/57664614/why-is-svg-not-identified-inside-a-node-using-contain-method-using-javascript
    width: rem(24px);
    height: rem(24px);
    fill: currentColor;
  }
}

.SearchPanel-content {
  --searchBar-content-borderTopWidth: 1px;

  position: absolute;
  top: calc(100% - var(--searchBar-content-borderTopWidth));
  left: 0;
  right: 0;
  height: calc(100vh - var(--header-height));
  overflow-y: auto;
  max-width: 100%;
  padding: 0 0 calc(var(--t-gutter-xl) + var(--serviceList-buttonHeight, 0));
  background: var(--c-white);
  color: var(--c-black);
  border-top: var(--searchBar-content-borderTopWidth) solid rgba(var(--c-black-rgb), 10%);
  transition: opacity 300ms;

  &[aria-hidden='true'] {
    opacity: 0;
    pointer-events: none;
  }

  &[aria-hidden='false'] {
    opacity: 1;
  }

  @include media-breakpoint-up('md') {
    left: var(--header-height);
    right: var(--header-navigationWidth, initial);
    height: initial;
    overflow-y: initial;
    padding: var(--t-gutter-xl) 0;
  }
}
