@import '../../../assets/css/config';

:root {
  --container-width: var(--t-width-xl);
  --container-background: var(--c-white);
  --container-padding-x: var(--t-gutter-lg);
}

@mixin Container-inner {
  max-width: var(--container-width);
  width: 100%;
  padding: 0 var(--container-padding-x);
  margin: 0 auto;
}

.Container {
  position: relative;

  &.has-background {
    --container-background: var(--c-grey-semi);
  }

  &.is-small {
    @include media-breakpoint-up('md') {
      --container-padding-x: var(--t-gutter-xxxl);
    }
  }

  &.is-padding-none {
    --container-padding-x: 0;
  }

  &.is-padding-sm {
    --container-padding-x: var(--t-gutter-sm);
  }

  &.is-padding-md {
    --container-padding-x: var(--t-gutter-md);
  }

  &.is-padding-lg {
    --container-padding-x: var(--t-gutter-lg);
  }

  &.is-padding-xl {
    --container-padding-x: var(--t-gutter-xl);
  }

  &.is-padding-xxl {
    --container-padding-x: var(--t-gutter-xxl);
  }
}

.Container-inner {
  @include Container-inner();
  background-color: var(--container-background);
  border-start-start-radius: 4px;
  border-start-end-radius: 4px;
}
