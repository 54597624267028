@import '../../../assets/css/config';

:root {
  --container-width: var(--t-width-xl);
  --container-background: var(--c-white);
  --container-padding-x: var(--t-gutter-lg);
}

.NewContainer {
  --Container-width: var(--container-width);
  --Container-background: var(--container-background);
  --Container-padding-x: var(--container-padding-x);
  --Container-columns: 1fr var(--Container-padding-x) min(calc(100% - var(--Container-padding-x) * 2), var(--Container-width)) var(--Container-padding-x) 1fr;
  --Container-column-default: 3 / -3;
  --Container-column-large: 2 / -2;
  --Container-column-full: 1 / -1;
  --Container-row-column: var(--Container-column-default);

  display: grid;
  grid-template-columns: var(--Container-columns);

  & > * {
    grid-column: var(--Container-row-column);
    max-width: 100%;
  }
}

.NewContainer--paddingX-xxl {
  --Container-padding-x: var(--t-gutter-xxl);
}

.NewContainer--shrunk {
  --Container-columns: var(--Container-padding-x) min(100%, var(--Container-width)) var(--Container-padding-x);
  --Container-column-default: 2 / -2;
  --Container-column-large: 1 / -1;
  --Container-column-full: 1 / -1;
}