@keyframes pageProgressAnimation {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

.PageProgress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  height: 4px;
  width: 100%;
  overflow: hidden;
  background-color: #ddd;

  &:before {
    display: block;
    position: absolute;
    content: '';
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: var(--c-secondary);
    animation: pageProgressAnimation 2s linear infinite;
  }

  @media print {
    display: none !important;
  }
}
