@import '../../../assets/css/_config.scss';

.SideMenuItem-link {
  display: inline-flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: var(--c-black);
  padding: 0;
  border: none;
  background: none;
  transition: color $transition-duration;
  cursor: pointer;

  &:hover,
  &:focus,
  &[aria-current='page'] {
    color: var(--c-secondary);
  }

  &[aria-expanded='true'] {
    svg {
      transform: rotateZ(-90deg);
    }
  }

  svg {
    height: rem(24px);
    width: rem(24px);
    color: var(--c-secondary);
    transform: rotateZ(90deg);
    transition: transform $transition-duration;
  }
}
