@import '../../assets/css/_config.scss';

.FormLabel {
  --formLabel-fontWeight: var(--fontWeight-bold);
  --formLabel-fontSize: 1em;

  display: block;
  font-family: var(--fontFamily-secondary);
  font-weight: var(--formLabel-fontWeight);
  font-size: var(--formLabel-fontSize);

  &[aria-hidden='true'] {
    @include u-srOnly;
  }

  &.is-invisible {
    visibility: hidden;
  }

  &.is-primary {
    color: var(--c-primary);
  }

  &.is-secondary {
    color: var(--c-secondary);
  }

  &.is-inherit {
    font-family: inherit;
    color: inherit;
    font-weight: inherit;
  }
}
