@import '../../../assets/css/config';

:root {
  --switchInput-height: #{rem(24px)};
  --switchInput-width: #{rem(48px)};
  --switchInput-cocheSize: #{rem(18px)};
  --switchInput-gap: 1em;

  --switchInput-mainColor: var(--c-black-rgb);
  --switchInput-altColor: var(--c-white-rgb);

  --switchInput-borderColor: var(--c-grey);
  --switchInput-tickColor: rgba(var(--c-black-rgb), 0.2);
  --switchInput-backgroundColor: none;
}

.SwitchInput {
  display: inline-flex;
  align-items: center;

  input {
    border: 0 !important;
    clip: rect(0em, 0em, 0em, 0em);
    font-size: 0;
    height: 0 !important;
    margin: -1px;
    overflow: hidden;
    padding: 0 !important;
    width: 0 !important;
    position: fixed;
    left: -1rem;
    top: -1rem;
  }

  label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  input + label {
    position: relative;
    padding-right: calc(var(--switchInput-width) + var(--switchInput-gap));
  }

  input + label::before,
  input + label::after {
    position: absolute;
    display: block;
    content: '';
  }

  input + label::before {
    right: 0;
    width: var(--switchInput-width);
    height: rem(24px);
    border-radius: rem(12px);
    background: var(--switchInput-backgroundColor);
    border: 1px solid var(--switchInput-borderColor);
    transition: background-color $transition-duration, border-color $transition-duration;
  }

  input + label::after {
    right: rem(4px);
    width: var(--switchInput-cocheSize);
    height: var(--switchInput-cocheSize);
    border-radius: 50%;
    background-color: var(--switchInput-tickColor);
    transform: translateX(
      calc((var(--switchInput-width) - var(--switchInput-cocheSize) - #{rem(8px)}) * -1)
    );
    transform-origin: 50%;
    transition: transform $transition-duration;
  }

  input:focus,
  input:hover {
    + label:before {
      box-shadow: 0 0 0 2px rgba(var(--switchInput-mainColor), 0.3);
    }
  }

  input:checked + label::after {
    transform: translateX(0);
    opacity: 1;
    --switchInput-tickColor: rgba(var(--switchInput-mainColor));
  }

  input:disabled + label::before {
    cursor: not-allowed;
    opacity: 0.5;
  }

  input:disabled + label::after {
    cursor: not-allowed;
  }

  &.is-primary {
    --switchInput-mainColor: var(--c-primary-rgb);
  }

  &.is-secondary {
    --switchInput-mainColor: var(--c-secondary-rgb);
  }

  &.is-white {
    --switchInput-mainColor: var(--c-white-rgb);
  }

  &.is-simple {
    --switchInput-tickColor: rgba(var(--switchInput-mainColor));
    --switchInput-backgroundColor: rgb(var(--switchInput-altColor));
    --switchInput-borderColor: rgba(var(--switchInput-mainColor));
  }

  &.is-srOnly {
    --switchInput-gap: 0px;

    .SwitchInput-labelContent {
      @include u-srOnly;
    }
  }
}

.SwitchInput-endLabel {
  margin-left: var(--switchInput-gap);
}
