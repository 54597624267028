@import '../../../assets/css/_config.scss';

.SidebarSection {
  ul {
    display: grid;
    row-gap: rem(8px);
  }

  .Overtitle {
    color: var(--c-secondary);
    font-size: rem(14px);
    text-transform: uppercase;
    letter-spacing: rem(2.8px);

    &::before {
      background: var(--c-secondary);
    }

    @include media-breakpoint-down('md') {
      &::before {
        content: none;
      }
    }

    @include media-breakpoint-up('md') {
      margin-bottom: 1em;
    }
  }
}

.SidebarSection-disclosureButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;
  text-align: left;

  &[aria-expanded='true'] {
    svg {
      transform: rotate(0.5turn);
    }
  }

  svg {
    height: rem(24px);
    width: rem(24px);
    min-width: rem(24px);
    fill: var(--c-secondary);
    transition: transform $transition-duration;
  }
}

.SidebarSection-disclosureContent {
  margin-top: rem(8px);
  transition: opacity $transition-duration;
  opacity: 0;
}

.SidebarSection-disclosureContent[data-enter] {
  opacity: 1;
}
