@import '../../../../assets/css/_config.scss';

.EditorialHeader {
  position: relative;
  overflow: hidden;
  background-color: var(--c-white);
  padding: rem(24px) var(--t-gutter-xxxl);
  margin: 0 calc(var(--t-gutter-xxxl) * -1);
  padding-bottom: rem(24px);

  border-top-right-radius: rem(4px);
  border-top-left-radius: rem(4px);

  .Breadcrumb {
    margin-top: 0;
    margin-bottom: rem(24px);
  }
}
