.Spacer {
  --spacer-inline: 0;
  --spacer-block: 0;

  margin-left: var(--spacer-inline);
  margin-right: var(--spacer-inline);
  margin-top: var(--spacer-block);
  margin-bottom: var(--spacer-block);

  // &:first-child {
  //   margin-top: 0;
  // }

  // &:last-child {
  //   margin-bottom: 0;
  // }
}
