@import '../../../assets/css/config';

.Icon {
  fill: currentColor;
  vertical-align: middle;

  &.is-xxs {
    width: rem(10px);
    height: rem(10px);
  }

  &.is-xs {
    width: rem(18px);
    height: rem(18px);
  }

  &.is-sm {
    width: rem(24px);
    height: rem(24px);
  }

  &.is-md {
    width: rem(32px);
    height: rem(32px);
  }

  &.is-lg {
    width: rem(48px);
    height: rem(48px);
  }

  &.is-xl {
    width: rem(64px);
    height: rem(64px);
  }

  &.is-spinning {
    animation: iconSpin 1s linear infinite;
  }

  &.is-paused {
    animation-play-state: paused;
  }
}

@keyframes iconSpin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
