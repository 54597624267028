@import '../../../assets/css/_config.scss';

.AccordionPanel {
  position: relative;
  background-color: var(--c-grey-light);
  border-radius: rem(8px);
  position: relative;

  & > dt {
    font-family: var(--fontFamily-secondary);
    font-weight: var(--fontWeight-bold);
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    position: relative;

    @include media-breakpoint-up('md') {
      flex-direction: row;
      align-items: center;
    }
  }

  & > dd {
    display: block;

    &[hidden] {
      display: none;
    }
  }

  @media print {
    & > dd {
      display: block !important;
    }
  }
}

.AccordionPanel-titleButton,
.AccordionPanel-iconButton {
  @include btn-reset;
  font-size: var(--fontSize-lg);
  display: inline-flex;
  flex-direction: column;
  padding: rem(24px);

  @include media-breakpoint-up('md') {
    padding: rem(32px) rem(48px);
  }

  &:hover,
  &::focus {
    color: var(--c-primary);
  }
}

.AccordionPanel-titleButton {
  flex-grow: 1;
  padding-inline-end: rem(24px);
  margin-inline-end: rem(64px);

  @include media-breakpoint-up('md') {
    margin-inline-end: initial;
  }
}

.AccordionPanel-iconButton {
  padding-inline-start: rem(24px);
  position: absolute;
  right: 0;
  top: 0;

  @include media-breakpoint-up('md') {
    position: static;
  }
}

.AccordionPanel-actions {
  padding: rem(8px);
}

.AccordionPanel-title {
  width: 100%;
}

.AccordionPanel-subtitle {
  font-family: var(--fontFamily-primary);
  font-weight: var(--fontWeight-normal);
  font-size: rem(13px);
  width: 100%;
}

.AccordionPanel-icon {
  height: rem(24px);
  width: rem(24px);
  fill: var(--c-primary);
  transition: transform $transition-duration;
}

.AccordionPanel.is-toggable {
  .AccordionPanel-titleButton,
  .AccordionPanel-iconButton {
    cursor: pointer;
  }
}

.AccordionPanel.is-open {
  & .AccordionPanel-icon {
    transform: rotate(0.5turn);
  }

  & > dt {
    border-bottom: rem(1px) solid rgba(var(--c-black-rgb), 0.1);
  }
}

.AccordionPanel-action {
  display: flex;
  align-items: center;
  padding: 0 rem(24px) rem(24px) rem(24px);
  white-space: nowrap;

  @include media-breakpoint-up('md') {
    padding: rem(32px) 0;
  }
}
