@import '../../../assets/css/_config.scss';

.SearchResultEmpty {
  max-width: #{rem(250px)};
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.SearchResultEmpty-icon {
  color: var(--c-primary);
  width: 2em;
  height: 2em;

  @include media-breakpoint-up('md') {
    width: 3em;
    height: 3em;
  }
}