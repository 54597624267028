@import '../../assets/css/_config.scss';

@mixin inputBase {
  --input-height: var(--t-size-md);
  --input-textColor: var(--c-black);
  --input-placeholderColor: currentColor;
  --input-accentColor: var(--c-secondary);
  --input-backgroundColor: transparent;
  --input-borderColor: rgba(var(--c-black-rgb), 0.2);
  --input-borderWidth: 1px;
  --input-borderRadius: 4px;

  background: var(--input-backgroundColor);
  font-family: inherit;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.3;
  color: rgba(var(--input-textColor), 1);
  box-shadow: 0 0 0 var(--input-borderWidth) var(--input-borderColor) inset;
  border-radius: var(--input-borderRadius);
  padding: 0 1em;
  appearance: none;
  border: none;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;
  height: var(--input-height);
  vertical-align: middle;
  display: inline-flex;
  align-items: center;

  &.is-lg {
    --input-height: var(--t-size-lg);
  }

  &.is-inline {
    width: auto;
  }
}

@mixin inputDisabled {
  cursor: not-allowed;
  opacity: 0.5;
}

@mixin inputFocus {
  --input-borderColor: var(--input-accentColor);
  --input-borderWidth: 2px;
}

@mixin inputHover {
  --input-borderWidth: 2px;
}

@mixin inputPlaceholder {
  font-style: italic;
  color: var(--input-placeholderColor);
}

@mixin inputText {
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  &[type='search'] {
    appearance: textfield;

    --input-borderColor: var(--input-accentColor);
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEwIDIuMjVhNy43NSA3Ljc1IDAgMCAxIDUuOTg1IDEyLjY3NGw2LjA0NSA2LjA0Ni0xLjA2IDEuMDYtNi4wNDYtNi4wNDVBNy43NSA3Ljc1IDAgMSAxIDEwIDIuMjV6bTAgMS41YTYuMjUgNi4yNSAwIDEgMCAwIDEyLjUgNi4yNSA2LjI1IDAgMCAwIDAtMTIuNXoiIGZpbGw9IiM1NTY5REEiLz48L3N2Zz4=');
    background-repeat: no-repeat;
    background-size: rem(24px);
    background-position: center right rem(8px);
    padding-right: rem(24px + 8px + 8px);

    &::placeholder {
      --input-placeholderColor: var(--input-borderColor);
    }
  }

  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-decoration {
    appearance: none;
  }
}

@mixin inputTextarea {
  height: auto;
  min-height: 20vh;
  padding-top: 1em;
  padding-right: 1em;
}

@mixin inputSelect {
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
    linear-gradient(135deg, currentColor 50%, transparent 50%);
  background-size: 5px 5px, 5px 5px;
  background-position: right 1.1em top 50%, right calc(1.1em - 5px) top 50%;
  padding-right: 2em;

  &::-ms-expand {
    display: none;
  }
}
