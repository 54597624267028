@import '../../../assets/css/config';

.MiniTeaserList {
  --MiniTeaserList-item-padding-y: var(--t-gutter-sm);
}

.MiniTeaserList.is-gap-none {
  --MiniTeaserList-item-padding-y: 0;
}

.MiniTeaserList.is-gap-sm {
  --MiniTeaserList-item-padding-y: var(--t-gutter-sm);
}

.MiniTeaserList.is-gap-md {
  --MiniTeaserList-item-padding-y: var(--t-gutter-md);
}

.MiniTeaserList.is-gap-lg {
  --MiniTeaserList-item-padding-y: var(--t-gutter-lg);
}

.MiniTeaserList-item {
  display: block;
  padding: var(--MiniTeaserList-item-padding-y) 0;

  &[hidden] {
    display: none;
  }
}

.MiniTeaserList-list:not(:first-child) .MiniTeaserList-item,
.MiniTeaserList-list .MiniTeaserList-item:not(:first-child), {
  border-top: 1px solid rgba(var(--c-black-rgb), 0.05);
}
