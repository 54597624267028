@import '../../../assets/css/_config.scss';

.SidebarItem {
  display: flex;
  align-items: center;
  list-style: none;

  &.is-multiline {
    align-items: flex-start;
  }
}

.SidebarItem-start {
  .Cta {
    margin-right: 1em;
    padding: rem(7px) rem(12px);

    svg {
      height: rem(16px);
      width: rem(16px);
    }
  }
}

.SidebarItem-content {
}
