@import './inputMixins';

.FormInput {
  @include inputBase();

  &[disabled] {
    @include inputDisabled();
  }

  &:hover {
    @include inputHover();
  }

  &:focus {
    @include inputFocus();
  }

  &:invalid,
  &::placeholder {
    @include inputPlaceholder();
  }
}

input.FormInput {
  @include inputText();
}

textarea.FormInput {
  @include inputTextarea();
}

select.FormInput {
  @include inputSelect();
}
