@import '../../../assets/css/_config.scss';
@import '../Anchor/anchorMixins.scss';

.Editorial {
  @include font-smoothing('on');

  font-size: 1em;
  color: inherit;

  &.has-scroll {
    max-width: 100%;
    overflow: auto;
  }

  header {
    font-size: var(--fontSize-lg);
  }

  a {
    @include anchor;
  }

  img {
    width: auto;
    height: auto;
    display: block;
    margin: auto auto 1em;
  }

  h1,
  h2,
  h3 {
    margin: 1em 0 0.5em 0;
  }

  h4,
  h5,
  h6 {
    margin: 0.75em 0 0.5em 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }

  p {
    margin: 1em 0;
  }

  ol,
  ul {
    margin: 1em 0;
    padding-left: 1em;
  }

  ul > li {
    list-style-type: disc;
    list-style-position: outside;
  }

  ul > li > ul > li {
    list-style-type: circle;
  }

  ol > li {
    list-style-type: decimal;
    list-style-position: outside;
  }

  ol.upper-alpha > li {
    list-style-type: upper-alpha;
  }

  li > ul,
  li > ol {
    margin: 0.5em 0 0.5em 2em;
  }

  li::marker {
    color: var(--c-primary);
  }

  dl {
    margin: 1em 0;
  }

  dt {
    margin: 0.5em 0;
    font-weight: bold;
    color: var(--c-primary);
  }

  dd {
    margin-left: 2em;
    margin-top: 0.5em;
  }

  figure figcaption {
    color: inherit;
    font-style: italic;
    font-size: 0.9em;
    text-align: left;
    line-height: 120%;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 1em 0;

    thead {
      th {
        font-family: var(--fontFamily-secondary);
        font-weight: var(--fontWeight-bold);
        position: relative;
        padding: rem(16px) rem(22px) rem(16px) rem(22px);
        text-transform: uppercase;
        font-size: rem(12px);
        color: var(--c-primary);

        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: rem(1px);
          background-color: rgba(var(--c-primary-rgb), 0.2);
          height: rem(16px);
        }

        @include media-breakpoint-up('md') {
          padding: rem(16px) 0 rem(16px) rem(22px);
        }
      }
    }

    tbody {
      th {
        color: var(--c-primary);
        font-weight: var(--fontWeight-normal);
      }

      tr:nth-child(2n + 1) {
        background-color: initial;

        td,
        th {
          background-color: rgba(var(--c-grey-light-rgb), 0.5);

          &:first-child {
            border-top-left-radius: rem(8px);
            border-bottom-left-radius: rem(8px);
          }

          &:last-child {
            border-top-right-radius: rem(8px);
            border-bottom-right-radius: rem(8px);
          }
        }
      }
    }

    td,
    th {
      text-align: start;
      padding: rem(12px) rem(22px) rem(12px) rem(22px);

      @include media-breakpoint-up('md') {
        padding: rem(12px) 0 rem(12px) rem(22px);
      }
    }
  }

  @media print {
    &,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }
  }

  .Paragraph {
    @include clearfix();
    position: relative;

    iframe {
      max-width: 100%;
    }

    blockquote {
      display: block;
      position: relative;
      font-size: rem(23px);
      color: var(--c-primary);

      margin-top: rem(42px);
      @include media-breakpoint-up(md) {
        margin-top: rem(72px);
      }

      &:before {
        content: '';
        display: block;
        height: rem(29px);
        width: rem(52px);
        margin-bottom: 28px;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCA1MiAyOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik00My45MDIgMjlDMzUuNDM1IDI2Ljc5IDI3LjIyIDE3Ljg4IDMwLjAyNyA4LjAxOCAzMS41NDEgMi43IDM2LjU0LS41MzYgNDIuMTMxLjA3M2M1LjI2LjU3NCA5LjUzNyA0Ljg2NCA5Ljg0OCA5Ljg4LjMzIDUuMy0zLjMgOS45NS04Ljc2NiAxMS4xMTItMS4xOS4yNTMtMi40NDUuMjI4LTMuODY1LjM0Ny4xMzggMy4xNDcgMS45NjYgNS40OSA0LjU1NCA3LjU4OG0tMjkuNjU4IDBDNS41NTcgMjYuNjkzLS40NTYgMTguODQ2LjAyNyAxMC42MzUuMzQ2IDUuMjMyIDQuNDI0LjgwOCA5LjczNi4xMDJjNS4yNjItLjcgMTAuMzY2IDIuMjY2IDEyLjE0MyA3LjA1NSAyLjY3NyA3LjIxLTMuMjE0IDE0LjY0OS0xMS4yNTIgMTQuMjEtLjIyNC0uMDEyLS40NS0uMDAyLS44NDUtLjAwMkMxMC4wOTYgMjQuNTggMTEuNzU4IDI3IDE0LjI0NCAyOSIgZmlsbD0iI0UyMDAxQSIgZmlsbC1ydWxlPSJldmVub2RkIi8+Cjwvc3ZnPgo=');
        background-repeat: no-repeat;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
          position: absolute;
          left: rem(-75px);
        }
      }

      & > :first-child {
        margin-top: 0;
      }

      & > :last-child {
        margin-bottom: 0;
      }

      & + p {
        display: block;
        font-size: rem(15px);
        color: var(--c-black);

        margin-bottom: rem(56px);
        @include media-breakpoint-up(md) {
          margin-bottom: rem(72px);
        }
      }
    }
  }

  .Paragraph {
    @include clearfix;
  }

  .Paragraph-leftAlign {
    text-align: left;
  }

  .Paragraph-rightAlign {
    text-align: right;
  }

  .Paragraph-centerAlign {
    text-align: center;
  }

  .Paragraph-justifyAlign {
    text-align: justify;
  }

  .Paragraph-colorPrimary {
    color: var(--c-primary);
  }

  .Paragraph-media {
    max-width: 100%;
    margin-bottom: 1em;

    * {
      margin: 0;
      display: block;
    }
  }

  .Paragraph-text {
    width: 100%;
  }

  .Paragraph-linkVariant {
    a {
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      border: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      &:before,
      &:hover::before {
        width: rem(42px);
        height: rem(32px);
        display: inline-block;
        margin-right: rem(8px);
        border-radius: rem(20px);
      }

      &:before {
        content: '';
        background: var(--c-secondary);
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTc5MiAxNTM2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNzkyIDE1MzY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZD0iTTE0MDgsOTI4djMyMGMwLDc5LjMtMjguMiwxNDcuMi04NC41LDIwMy41UzExOTkuMywxNTM2LDExMjAsMTUzNkgyODhjLTc5LjMsMC0xNDcuMi0yOC4yLTIwMy41LTg0LjVTMCwxMzI3LjMsMCwxMjQ4VjQxNgoJYzAtNzkuMywyOC4yLTE0Ny4yLDg0LjUtMjAzLjVTMjA4LjcsMTI4LDI4OCwxMjhoNzA0YzkuMywwLDE3LDMsMjMsOXM5LDEzLjcsOSwyM3Y2NGMwLDkuMy0zLDE3LTksMjNzLTEzLjcsOS0yMyw5SDI4OAoJYy00NCwwLTgxLjcsMTUuNy0xMTMsNDdzLTQ3LDY5LTQ3LDExM3Y4MzJjMCw0NCwxNS43LDgxLjcsNDcsMTEzczY5LDQ3LDExMyw0N2g4MzJjNDQsMCw4MS43LTE1LjcsMTEzLTQ3czQ3LTY5LDQ3LTExM1Y5MjgKCWMwLTkuMywzLTE3LDktMjNzMTMuNy05LDIzLTloNjRjOS4zLDAsMTcsMywyMyw5UzE0MDgsOTE4LjcsMTQwOCw5Mjh6IE0xNzkyLDY0djUxMmMwLDE3LjMtNi4zLDMyLjMtMTksNDVzLTI3LjcsMTktNDUsMTkKCXMtMzIuMy02LjMtNDUtMTlsLTE3Ni0xNzZsLTY1Miw2NTJjLTYuNyw2LjctMTQuMywxMC0yMywxMHMtMTYuMy0zLjMtMjMtMTBMNjk1LDk4M2MtNi43LTYuNy0xMC0xNC4zLTEwLTIzczMuMy0xNi4zLDEwLTIzbDY1Mi02NTIKCWwtMTc2LTE3NmMtMTIuNy0xMi43LTE5LTI3LjctMTktNDVzNi4zLTMyLjMsMTktNDVzMjcuNy0xOSw0NS0xOWg1MTJjMTcuMywwLDMyLjMsNi4zLDQ1LDE5UzE3OTIsNDYuNywxNzkyLDY0eiIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4K');
        background-repeat: no-repeat;
        background-size: rem(16px);
        background-position: center;
      }

      &:hover::before {
        border: rem(1px) solid rgba(var(--c-black-rgb), 0.2);
        background: var(--c-white);
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhbHF1ZV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTc5MiAxNTM2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxNzkyIDE1MzY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggZD0iTTE0MDgsOTI4djMyMGMwLDc5LjMtMjguMiwxNDcuMi04NC41LDIwMy41UzExOTkuMywxNTM2LDExMjAsMTUzNkgyODhjLTc5LjMsMC0xNDcuMi0yOC4yLTIwMy41LTg0LjVTMCwxMzI3LjMsMCwxMjQ4VjQxNgoJYzAtNzkuMywyOC4yLTE0Ny4yLDg0LjUtMjAzLjVTMjA4LjcsMTI4LDI4OCwxMjhoNzA0YzkuMywwLDE3LDMsMjMsOXM5LDEzLjcsOSwyM3Y2NGMwLDkuMy0zLDE3LTksMjNzLTEzLjcsOS0yMyw5SDI4OAoJYy00NCwwLTgxLjcsMTUuNy0xMTMsNDdzLTQ3LDY5LTQ3LDExM3Y4MzJjMCw0NCwxNS43LDgxLjcsNDcsMTEzczY5LDQ3LDExMyw0N2g4MzJjNDQsMCw4MS43LTE1LjcsMTEzLTQ3czQ3LTY5LDQ3LTExM1Y5MjgKCWMwLTkuMywzLTE3LDktMjNzMTMuNy05LDIzLTloNjRjOS4zLDAsMTcsMywyMyw5UzE0MDgsOTE4LjcsMTQwOCw5Mjh6IE0xNzkyLDY0djUxMmMwLDE3LjMtNi4zLDMyLjMtMTksNDVzLTI3LjcsMTktNDUsMTkKCXMtMzIuMy02LjMtNDUtMTlsLTE3Ni0xNzZsLTY1Miw2NTJjLTYuNyw2LjctMTQuMywxMC0yMywxMHMtMTYuMy0zLjMtMjMtMTBMNjk1LDk4M2MtNi43LTYuNy0xMC0xNC4zLTEwLTIzczMuMy0xNi4zLDEwLTIzbDY1Mi02NTIKCWwtMTc2LTE3NmMtMTIuNy0xMi43LTE5LTI3LjctMTktNDVzNi4zLTMyLjMsMTktNDVzMjcuNy0xOSw0NS0xOWg1MTJjMTcuMywwLDMyLjMsNi4zLDQ1LDE5UzE3OTIsNDYuNywxNzkyLDY0eiIvPgo8L3N2Zz4K');
        background-repeat: no-repeat;
        background-size: rem(16px);
        background-position: center;
      }
    }
  }

  .Paragraph-greyBackground {
    z-index: 0;
    background-color: var(--c-grey-light);
    display: flex; // To avoid merging margins
    flex-direction: column;

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 100vw;
      left: calc(50vw - rem(32px));
      background-color: var(--c-grey-light);
      transform: translateX(-50%);
      z-index: -1;

      @include media-breakpoint-up('md') {
        left: calc(50vw - ((100vw - (100vw - rem(32px))) / 2) - rem(150px));
      }

      @include media-breakpoint-up('lg') {
        left: calc(50vw - ((100vw - var(--t-width-xl)) / 2) - rem(150px));
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .is-left .Paragraph-media {
      float: left;
      margin-right: 1em;
    }

    .is-right .Paragraph-media {
      float: right;
      margin-left: 1em;
    }

    .is-left .Paragraph-media,
    .is-right .Paragraph-media {
      max-width: 49%;
    }
  }

  @include media-breakpoint-up(md) {
    .is-left .Paragraph-media,
    .is-right .Paragraph-media {
      max-width: 32%;
    }
  }

  .Paragraph-accordion {
    margin-top: rem(10px);

    details {
      border-bottom: rem(1px) solid rgba(var(--c-black-rgb), 0.1);
    }

    summary {
      --editorial-summary-color: inherit;

      font-family: var(--fontFamily-secondary);
      font-weight: var(--fontWeight-normal);
      list-style: none;
      padding: rem(21px) 0;
      padding-right: rem(24px + 8px);
      color: var(--editorial-summary-color);
      cursor: pointer;
      font-weight: bold;
      font-size: rem(15px);
      position: relative;

      &::-webkit-details-marker {
        display: none;
      }

      &:hover,
      &:focus {
        --editorial-summary-color: var(--c-primary);
      }

      &:after {
        content: '';
        display: block;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZD0ibTE4LjUgOS40NzgtNiA2LjAyMi02LTYuMDIyLjk3NS0uOTc4IDUuMDI1IDUuMDQ0TDE3LjUyNSA4LjV6IiBmaWxsPSIjRTIwMDFBIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+Cg==');
        background-size: rem(24px);
        width: rem(24px);
        height: rem(24px);
        transition: transform $transition-duration;
        position: absolute;
        top: calc(50% - #{rem(24px / 2)});
        right: 0;
      }
    }

    summary + div {
      padding: 0 0 rem(21px);
    }

    details[open] summary:after {
      transform: rotate(0.5turn);
    }
  }

  // Weird rule to prevent having a margin if the editorial content is the first element
  &:first-child > :first-child {
    margin-top: 0;
  }

  .Emphasis-Red {
    color: var(--c-error);
  }
  .Text-Indent {
    margin-left: rem(10px);
  }
}

@import '../ParagraphFile/ParagraphFile.scss';
