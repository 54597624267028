@import '../../../assets/css/_config.scss';

.Overlay {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
    color: transparent;
    pointer-events: none;
    z-index: -1;
    position: relative;

    img {
      display: block;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      &.Logo-em {
        width: var(--header-height) !important;
        height: auto !important;
        align-self: flex-start;
        position: absolute;
        top: auto;
        left: auto;
        right: 38em;
        bottom: auto;
        z-index: 1;
        &.parcoursupPage {
          right: 24.9em;
          &.signingUp {
            right: 23.3em;
          }
          &.accountChoice {
            right: 24.7em;
          }
        }
        &.resetPasswordPage {
          right: 26.15em;
        }
      }
    }
  }

  @media print {
    display: none !important;
  }
}
