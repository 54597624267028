@import '../../../assets/css/_config.scss';

.Overtitle {
  font-family: var(--fontFamily-secondary);
  font-weight: var(--fontWeight-bold);
  position: relative;
  display: flex;
  align-items: center;
  font-size: rem(20px);
  color: var(--c-primary);

  &::before {
    content: '';
    right: 100%;
    height: 1px;
    width: rem(20px);
    background-color: var(--c-primary);
    margin-right: rem(10px);
  }
}
